.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  z-index: 10;
  top: 10px;
  left: 10px;
  width: 200px;
  position: fixed;
}

.demo {
  width: 80px;
  background: #e43;
  position: fixed;
  top: 8px;
  left: -24px;
  text-align: center;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 10000;
  font-size: 10px;
  line-height: 15px;
  pointer-events: none;
}
