.card{
    position: fixed;
    bottom:0px;
    border-radius: 10px 10px 0 0;
    background-color: rgb(236, 236, 236);
    height: 80px;
    max-height: 80px;
    width: 100vw;
    max-width: 100vw;
    display: flex;
    box-shadow: 2px 5px #8888881f;
    z-index: 300;
    padding: 10px 0 10px 0;
}

.card-logo{
    padding-left: 10px;
    height: 80px;
    width: 80px;
    object-fit: contain;
}

.card-info{
    padding-left: 20px;
    text-align: left;
}

.driver{
    font-weight: bold;
}

.partner{
    color: grey;
}

.eta{
    color: green;
}

.card-info > i{
    font-style: normal;
}