.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgb(234, 234, 234);
  text-align: center;
}
.error-card {
  margin: auto;
  position: relative;
  width: 70vw;
  height: 200px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}
.error-image {
  top: -30px;
  height: 60px;
}

.error-message{
     color: red;
}

.debug-hidden-message{
    color: white;
}