.loading{
    position: fixed;
    height: 100vh;
    width: 100vw;
    line-height: 100vh;
    background-color: rgb(234, 234, 234);
    text-align: center;
}
.loading-image{
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}